export function acvityVipType() {
  return [
    {
      name: '活动细则',
      type: 'rule',
    },
    {
      name: '立即注册',
      url: '',
    },
    {
      name: '邀请他人',
      type: 'invite',
    },
    {
      name: '提交审核',
      type: 'examine',
      desc1: '恭喜您提交成功<br/>可添加工作人员二维码获得审核结果',
      qrcodeUrl: 'https://download-cos.yofish.com/youyu-gongjushiyebu/20220129104114654-79.png',
      favicon: 'favicon',
      shareUrl: 'https://dk.hanmilin.com/?uid=aRrzwI',
    },
  ];
}

export function acvityCardType() {
  return [
    {
      name: '活动细则',
      type: 'rule',
    },
    {
      name: '立即注册',
      url: '',
    },
    {
      name: '邀请他人',
      type: 'invite',
    },
    {
      name: '审核入口',
      type: 'examine',
      taskId: 4,
    },
  ];
}

export function acvityPacketType() {
  return [
    {
      name: '活动细则',
      type: 'rule',
    },
    {
      name: '立即注册',
      url: '',
    },
    {
      name: '邀请他人',
      type: 'invite',
    },
    {
      name: '审核入口',
      type: 'examine',
      taskId: 5,
    },
  ];
}
