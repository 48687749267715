<template>
  <ul>
    <li v-for="(item, index) in list" :key="`card_${index}`">
      <a v-if="item.jumpUrl && !item.isAppUrl" :href="item.jumpUrl"
        target="_self">{{ item.name }}</a>
      <span v-if="item.jumpUrl && item.isAppUrl"
        @click="jumpUrlAction(item.jumpUrl, item)">{{ item.name }}</span>
      <span v-if="!item.jumpUrl" @click="clickName(item)"
        :class="{ default: item.type === 'invite' && !item.shareUrl }">{{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CardBottom',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickName(data) {
      if (data.type === 'invite' && !data.shareUrl) return;
      this.$emit('open-dialog', data);
    },
    jumpUrlAction(value, item) {
      console.log(value);
      if (!item.isAndroid) {
        window.location.href = value;
      }
      if (item.isAndroid) {
        window.android.goToActivity2(value);
      }
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  display: flex;
  width: 100%;
  border-top: 1PX solid #e7e7e7;
  padding: 15px 0 0 0;
  li {
    flex: 1;
    font-size: 26px;
    font-weight: 600;
    color: #F26858;
    line-height: 37px;
    text-align: center;
    position: relative;
    span.default {
      color: #cccccc;
    }
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 10px;
      width: 1PX;
      height: 20px;
      background-color: #e7e7e7;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    a {
      position: relative;
      color: #F26858;
      text-decoration: none;
      &:active {
        color: #F26858;
        text-decoration: none;
      }
    }
  }
}
</style>
