<template>
  <div class="upload-bg">
    <div class="avatar-uploader">
      <input type="file" :accept="`${fileType}/*`" name="uploader-input"
        class="uploader-file" @change="fileChange" ref="uploadFile"
        :multiple="false"
        style="opacity: 0;">
        <!-- {{ tempUrl }} -->
        <!-- <img v-if="tempUrl && fileType === 'image'" :src="tempUrl" class="avatar" /> -->
        <!-- <div class="video-type" v-if="(videoUrl || tempUrl) && fileType === 'video'">
          <i class="el-icon-video-camera-solid video-icon"></i>
        </div> -->
      <!-- <i v-if="!tempUrl" class="el-icon-plus avatar-uploader-icon"></i> -->
      <img :src="avatorUrl" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImageUpload',
  data() {
    return {
      tempUrl: '',
      tempUrl1: '',
    };
  },
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    },
    fileType: {
      type: String,
      default: 'image',
    },
    avatorUrl: {
      type: String,
      default: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAyCAMAAAA3KEgwAAAArlBMVEXvXFMAAADz8/PuXlPz7+/wioTvaWLvZFvvX1fy0M7wXFPvXFXy29ny19XvW1PwXFTz6eny4+Lyvrvyurbxq6bvf3nvXFPwXVPy09HxXlXz7OvyxsPyxMHxsa7xpaHxoJvwkYzwhX7vcWnvZl7vXFPvW1TxXVTvfHXwXFPxXFPvXVL/VVXxrqrxn5nxl5Hvd2/vbWXvYlrxXFPwXFPuXVPwXVXpWE3xXVPwXFP0YFVhjQrCAAAAOnRSTlPmAOYu5ubm5ubm2Tvm5uIh5ubm5ubmw4vmCubm5ubm5ubm5ubRrU/myJxbBObm5ubm5sK5a0IXfnUtZWBsngAAAcxJREFUSMedltdygzAQRdcEUQw2BlPce+89yf//WFRiW/CAVzpv3NEZVivtjKDypv2T1uGfWfaofESSHynIzH5V5DXkWW3xcgBFFnh5AUW+8fIGimQ42csuVUBTvWTeSw7moMw8ELJXBQ2qHpMD6mrZAZXnoMm8Ah5o40GmL2eQqgmjrhtPQZCCWrtig+ILu1y1EkISS04mpsGI4QPTQc3g1AbTd9EicqGcYcN40Rg+UyKCbqlq94wcPVvk55B/jspcxzUYphtFrikKdYCzDGlcvuUmL3Zv8yL2fANNEJzJaFLqjg1Ky3o1vcW+x4CjxnpsSx3gAc5N2HaPcnJkG09Q8o6ujPJRRKMdumqSjwhrAkpm3T3loxPrPkpmG3QKB8/agJI7dKVVGBEadVCyL061ePI+QhX3q5+P+vyOYSCsRitXdYcfAAYnLE5dlwahAygOfBJeix0+JwfAISaylQAnaUkzicASM+/3h8O+bzBCC9BMqCLjT0ABO5LdyAY1lj1TmGZvCerYZBDHA1L21zpoIdSVtkvVtbZL1YW2S9VtquumW6h4dc12eew1tKlruRvxDrvP1N3Z/fkCbN+uaur11pbfnu3gC01ATc4fYv8ZTrPSb70AAAAASUVORK5CYII=',
    },
  },
  watch: {
    imageUrl: {
      handler(val) {
        this.tempUrl = val;
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    fileChange(event) {
      const { target } = event;
      console.log(target.files, 'files----', target.files.length);
      if (!target.files.length) return;
      if (this.fileType === 'image') {
        this.tempUrl = URL.createObjectURL(target.files[0]);
        this.validImage(target.files);
      } else {
        this.tempUrl = window.URL.createObjectURL(target.files[0]);
        this.$emit('video-upload', target.files);
      }
      // this.$emit('change', this.tempUrl);
      console.log(this.tempUrl);
    },
    handleChange(file, fileList) { // 将上传图片的原路径赋值给临时路径
      console.log(fileList);
      this.tempUrl = URL.createObjectURL(file.raw);
      this.$emit('change', this.tempUrl);
      console.log(this.tempUrl);
    },
    validImage(file) {
      let newFile = file[0];
      const isJPG = newFile.type === 'image/jpeg' || newFile.type === 'image/png'
       || newFile.type === 'image/jpg';
      const isLt2M = newFile.size / 1024 / 1024 < 5;
      if (!isJPG) {
        return this.$toast({ content: '上传图片只能是 JPG 或 PNG 格式!' });
      }
      if (!isLt2M) {
        return this.$toast({ content: '上传图片大小不能超过 5MB!' });
      }
      if (isJPG && isLt2M) {
        const data = { file, tempUrl: this.tempUrl };
        this.$emit('image-upload', data);
      }
      this.$emit('change', this.tempUrl);
    },
    httpRequest(item) {
      const isJPG = item.file.type === 'image/jpeg' || item.file.type === 'image/png';
      const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      console.log(item);
      if (isJPG && isLt2M) {
        // post上传图片
        let App = this;
        // 定义FormData对象 存储文件
        let mf = new FormData();
        // 将图片文件放入mf
        // mf.append('file', item.file, item.file.name);
        this.$emit('image-upload', item.file);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .avatar-uploader {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    cursor: pointer;
    line-height: 80px;
    vertical-align: top;
    text-align: center;
    position: relative;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    img {
      width: 100%;
    }
    i {
      // width: 100px;
      // height: 100px;
      // position: absolute;
      // left: 0;
      // top: 0;
      // text-align: center;
      // line
    }
    .video-icon {
      font-size: 30px;
    }
  }
</style>
